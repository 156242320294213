<template>
    <div class="offices-cover cover container-fluid px-0 mt-5">
        <div class="container">
            <div class="row mx-2">
                <div class="col-12 col-lg-6 cover-texts-bg my-5 md:ml-5">
                    <h4 class="text-white mx-5 mt-5 mb-4">ЗА ОФИСИ</h4>
                    <p class="text-white mx-5 my-4">Приятната работна среда, правилната поддръжка на чистотата и редa във Вашия офис ще допринесат за комфорта на служителите и ще спомогнат за повишаването на авторитета на бизнеса Ви пред Вашите клиенти.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CustomersCover",
    }
</script>