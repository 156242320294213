<template>
    <div>
        <SubOffices />
        <OfficesCover />
        <div class="offices container mt-5">
            <h3>За Офиси</h3>
            <p>
                Cleaner предлага професионално почистване на офиси с различна площ и степен на сложност, административни сгради, общи части и прилежащи площи. Услугата е ориентирана както към малки фирми, така и към обширни корпоративни офиси с голямо РЗП.
                <br><br>  
                Всяко помещение има своята специфика, която се отразява на подредбата, обзавеждането и оборудването на работните помещения. Ние се съобразяваме с Вашите нужди и вътрешни правила, извършваме услугата професионално, без да пречим на работния процес.
                <br><br>
                Качеството на нашите услуги гарантира блестяща работна обстановка и отлична атмосфера за труд. Поискайте оферта и ще получите цялостно персонално решение, съобразено с Вашите конкретни нужди и изисквания.
            </p>
            <h4 class="mb-3 mt-5">Допълнитени услуги</h4>
            <div class="add-services flex flex-row">
                <ul class="two-cols">
                    <li>Техническа поддръжка</li>
                    <li>Доставка на хигиенни консумативи</li>
                    <li>Доставка на почистващи препарати</li>
                    <li>Измиване на витрини и фасади</li>
                    <li>Разделно събиране на отпадъци</li>
                    <li>Дезинсекция и дератизация</li>
                    <li>Дежурен целодневен персонал</li>
                    <li>Грижа за интериорна растителност</li>
                    <li>Почистване на сняг</li>
                    <li>Почистване на външни площи и гаражи</li>
                    <li>Други услуги, заявени от клиента</li>
                </ul>
            </div>
            <h4 class="mb-3 mt-5">Ползи и предимства</h4>
            <div class="features">
                <span>ЕКИП: </span>Нашите служители са професионалисти с дългогодишен опит, преминали всички нужни курсове.
                <br>
                <span>КОНТРОЛ: </span> За всеки обект отговаря отделен мениджър, който следи за качеството на услугата.
                <br>
                <span>АСИСТЕНТ: </span> Отговорно лице 24/7 за всички Ваши специални изисквания, извънредни ситуации и поръчки.
                <br>
                <span>ХЕЛП ДЕСК: </span>Онлайн система, спомагаща за по-лесна обратна връзка и съдействие на клиента.
                <br>
                <span>ИНДИВИДУАЛНО ОТНОШЕНИЕ: </span>Получавате цялостно персонално решение, съобразено с Вашите конкретни изисквания.
                <br>
                <span>ПЪЛНА ПРОЗРАЧНОСТ: </span>Всички разходи за услуги, доставки и материали в една месечна фактура.
            </div>
            <h4 class="mb-3 mt-5">Как работим?</h4>
            <p>
                Поради спецификата на всяка от дейностите и зоните за почистване, за нас е важно да разберем повече за характерните особености на обекта – квадратура, брой помещения, брой служители, степен на заетост и други. При необходимост наш специалист ще направи допълнителен оглед. Така ще можем да дадем коректна информация за организирането на услугата и ще калкулираме най-добрата цена. Получавате цялостно персонално решение, съобразено с Вашите конкретни изисквания.
            </p>
            <br>
            <p>
                Попитайте за нашите услуги като изпратите Вашето запитване онлайн или се свържете с нас на посочените телефони.
            </p>
        </div>
        <Badges />
    </div>
</template>

<script>
    import SubOffices from './offices/SubOffices'
    import OfficesCover from './includes/OfficesCover'
    import Badges from './includes/Badges'

    export default {
        name: "Offices",
        components: {
            SubOffices,
            OfficesCover,
            Badges
        }
    }
</script>