<template>
    <div class="categories-wrapper container text-center">
        <div class="row sub-offices">
            <div class="col-12 col-lg-6 my-4" @mouseenter="isSubscriptionActive = true" @mouseleave="isSubscriptionActive = false">
                <router-link class="category-h column-1 d-inline-block w-100" to="/services/offices-subscription">
                <transition name="sub-offices-slide" type="animation" mode="out-in" appear>
                    <span class="caption caption-sm" v-show="isSubscriptionActive">
                    <p class="p-4 text-center">Гъвкави предложения за чистотата в офиса. 
                    </p>
                    </span>
                </transition>
                <span class="title">Абонаментно почистване</span>
                </router-link>
            </div>
            <div class="col-12 col-lg-6 my-4" @mouseenter="isGeneralActive = true" @mouseleave="isGeneralActive = false">
                <router-link class="category-h column-2 d-inline-block w-100" to="/services/offices-general">
                <transition name="sub-offices-slide" type="animation" mode="out-in" appear>
                    <span class="caption caption-sm" v-show="isGeneralActive">
                    <p class="p-4 text-center">Освежете работното пространство!
                    </p>
                    </span>
                </transition>
                <span class="title">Основно почистване</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SubOffices",
        data() {
            return {
                isSubscriptionActive: false,
                isGeneralActive: false,
            }
      }
    }
</script>